var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _c("div", { staticClass: "row" }, [
      _c("table", { staticClass: "table table-borderless" }, [
        _c("tr", [
          _vm._m(0),
          _c("td", [
            _c("div", { staticClass: "d-flex gap-3 align-items-center" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.expiration_days,
                    expression: "expiration_days",
                  },
                ],
                staticClass: "form-control w-120",
                attrs: {
                  min: "1",
                  max: "999999",
                  type: "number",
                  disabled: _vm.disabled,
                  name: "expiration_days",
                },
                domProps: { value: _vm.expiration_days },
                on: {
                  change: _vm.update,
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.expiration_days = $event.target.value
                  },
                },
              }),
              _c("p", { staticClass: "mb-0" }, [_vm._v("days")]),
            ]),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", { staticClass: "w-200" }, [
      _c("label", { staticClass: "form-control-label" }, [
        _c("h4", [_vm._v("Expiration Period")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }