var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _c("div", { staticClass: "row" }, [
      _c("table", { staticClass: "table table-borderless" }, [
        _c("tr", [
          _vm._m(0),
          _c("td", [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.success_message,
                  expression: "success_message",
                },
              ],
              staticClass: "form-control",
              attrs: {
                type: "text",
                maxlength: "60",
                disabled: !_vm.canEdit,
                name: "success_message",
              },
              domProps: { value: _vm.success_message },
              on: {
                change: _vm.update,
                input: function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.success_message = $event.target.value
                },
              },
            }),
          ]),
        ]),
        _c("tr", [
          _vm._m(1),
          _c("td", [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.expiration_message,
                  expression: "expiration_message",
                },
              ],
              staticClass: "form-control",
              attrs: {
                type: "text",
                maxlength: "60",
                disabled: !_vm.canEdit,
                name: "expiration_message",
              },
              domProps: { value: _vm.expiration_message },
              on: {
                change: _vm.update,
                input: function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.expiration_message = $event.target.value
                },
              },
            }),
          ]),
        ]),
        _c("tr", [
          _vm._m(2),
          _c("td", [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.invalid_message,
                  expression: "invalid_message",
                },
              ],
              staticClass: "form-control",
              attrs: {
                type: "text",
                maxlength: "60",
                disabled: !_vm.canEdit,
                name: "invalid_message",
              },
              domProps: { value: _vm.invalid_message },
              on: {
                change: _vm.update,
                input: function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.invalid_message = $event.target.value
                },
              },
            }),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", { staticClass: "w-200" }, [
      _c("h4", [_vm._v("Success message (max 60 char)")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [_c("h4", [_vm._v("Expiration message (max 60 char)")])])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [_c("h4", [_vm._v("Invalid message (max 60 char)")])])
  },
]
render._withStripped = true

export { render, staticRenderFns }