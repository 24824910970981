<template>
  <div class="container">
    <div class="row">
      <table class="table table-borderless">
        <tr>
          <td class="w-200">
            <label class="form-control-label">
              <h4>Expiration Period</h4>
            </label>
          </td>
          <td>
            <div class="d-flex gap-3 align-items-center">
              <input
                min="1"
                max="999999"
                type="number"
                @change="update"
                :disabled="disabled"
                name="expiration_days"
                v-model="expiration_days"
                class="form-control w-120"
              />
              <p class="mb-0">days</p>
            </div>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import { expirationService } from "@/api/client";

export default {
  name: "ExpirationPeriod",

  props: ["campaign"],

  data() {
    return {
      expiration_days: 0,
    };
  },

  computed: {
    disabled() {
      return !["draft", "paused"].includes(this.campaign.status);
    },
  },

  methods: {
    async update(event) {
      const { name } = event.currentTarget;

      const form = {};
      form[name] = +this[name];

      try {
        await expirationService.updateCampaign(this.campaign.id, form);
        this.$notify(`Campaign updated.`);
        this.$emit("updated");
      } catch (error) {
        error.response
          ? this.$dialogs.alert(error.response.data)
          : this.$dialogs.alert(`Update campaign error.`);
      }
    },
  },

  mounted() {
    const { expiration_days } = this.campaign;
    this.expiration_days = expiration_days;
  },
};
</script>

<style lang="scss" scoped>
.row {
  margin-bottom: 1rem;
}

table tr td input[type="text"] {
  width: 60%;
}

.form-control-label {
  color: #37474f;
  font-weight: 500;
  font-size: 1.286rem;

  padding-left: 0px;
  margin-bottom: 0.5em;
}
</style>
