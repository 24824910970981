<template>
  <div class="col-md-10" v-if="campaign && status != null">
    <div
      class="wrapper-upload-form"
      v-if="action == 'ready' && status.active == 0"
    >
      <label class="mx-5">
        <h4>Target SKUs (including sub SKUs))</h4>
      </label>

      <button
        type="button"
        class="btn btn-primary btn-round"
        :disabled="disabled"
        @click="setAction('editing')"
      >
        Edit
      </button>
    </div>
    <div
      class="wrapper-upload-form"
      v-else-if="action == 'editing' && status.active == 0"
    >
      <label class="mx-5">
        <h4>Target SKUs (including sub SKUs)</h4>
      </label>
      <div>
        <button
          type="button"
          class="btn btn-round btn-primary mx-10"
          @click="handleVerify"
          :disabled="disabled"
        >
          Verify
        </button>
        <button
          type="button"
          class="btn btn-round btn-default"
          @click="handleCancel"
          :disabled="disabled"
        >
          Cancel
        </button>
      </div>
    </div>
    <div class="wrapper-upload-form" v-else-if="status.active == 1">
      <label class="mx-5">
        <h4>Target SKUs (including sub SKUs)</h4>
      </label>

      <label class="label-blue" v-if="status.state == 'verify'">
        Verifying change
      </label>

      <label class="label-blue" v-else-if="status.state == 'done-verify'">
        Click to commit change
      </label>

      <div v-if="status.state == 'verify' && campaign.status != 'draft'">
        <button type="button" class="btn btn-round btn-primary mx-10" disabled>
          Commit
        </button>
        <button type="button" class="btn btn-round btn-default" disabled>
          Cancel
        </button>
      </div>
      <div v-else-if="status.state == 'done-verify'">
        <button
          type="button"
          class="btn btn-round btn-primary mx-10"
          @click="confirmUpdate"
          :disabled="disabled"
        >
          Commit
        </button>
        <button
          type="button"
          class="btn btn-round btn-default"
          @click="handleCancel"
          :disabled="disabled"
        >
          Cancel
        </button>
      </div>
    </div>

    <Selectize
      multiple
      :disabled="!canEdit"
      v-model="skuSelected"
      :settings="selectizeSetting"
    >
      <option v-for="(sku, index) in skus" :key="index" :value="sku.PRO_CODE">
        {{ sku.PRO_NAME_EN + " - " + sku.PRO_CODE }}
      </option>
    </Selectize>
  </div>
</template>

<script>
import Selectize from "vue2-selectize";
import { expirationService } from "@/api/client";

export default {
  props: ["campaign"],

  components: {
    Selectize,
  },

  data() {
    return {
      skus: [],
      status: null,
      result: null,
      skuSelected: [],
      action: "ready",
      pullingId: null,
      selectizeSetting: {
        selected: [],
        plugins: ["remove_button"],
        // create: this.add,
      },
    };
  },

  computed: {
    disabled() {
      return ["archived", "end", "scheduled"].includes(this.campaign.status);
    },
    canEdit() {
      return (
        this.action == "editing" &&
        !["archived", "end", "scheduled"].includes(this.campaign.status)
      );
    },
  },

  watch: {
    status(n) {
      if (
        n.active === 1 &&
        n.type == "expiration sku" &&
        n.state == "verify" &&
        this.pullingId === null
      ) {
        this.setAction("ready");
        this.startPullingStatus();
        return;
      }

      if (
        n.active === 1 &&
        n.type == "expiration sku" &&
        n.state == "execute" &&
        this.pullingId === null
      ) {
        this.startPullingStatus();
        return;
      }

      if (
        n.active === 1 &&
        n.type == "expiration sku" &&
        n.state == "done-execute"
      ) {
        this.setAction("ready");
        this.getUploadStatus();
        return;
      }

      if (
        n.active === 1 &&
        n.type == "expiration sku" &&
        n.state == "done-verify"
      ) {
        this.setAction("ready");
        this.stopPullingStatus();
        return;
      }

      if (n.active === 0) {
        this.setAction("ready");
        this.stopPullingStatus();
        return;
      }
    },
  },

  methods: {
    setAction(action) {
      this.action = action;
    },

    async handleCancel() {
      if (this.status.active === 1) {
        await expirationService.tryCancelUpdateSKU(this.campaign.id);
        this.$notify("Cancel success");
        this.getUploadStatus();
      }

      this.setAction("ready");
      await this.fetchSelectedSKUs();
    },

    async handleVerify() {
      if (this.campaign.sku.length === 0 && this.skuSelected.length <= 0) {
        return;
      }

      this.campaign.status == "draft"
        ? await this.update()
        : await this.tryUpdate();

      await this.getUploadStatus();
      this.campaign.sku = this.skuSelected;
    },

    async update() {
      try {
        const form = { sku: this.skuSelected };

        await expirationService.updateSKU(this.campaign.id, form);

        this.$notify(`Target SKUs Updated.`);
        this.$emit("updated");
      } catch (error) {
        error.response
          ? this.$dialogs.alert(error.response.data)
          : this.$dialogs.alert(`Target SKUs update error.`);
      }
    },

    async tryUpdate() {
      try {
        const form = { sku: this.skuSelected };

        const result = await expirationService.tryUpdateSKU(
          this.campaign.id,
          form
        );

        if (result.error) {
          this.cancelProcess();
          this.$dialogs.alert(result.error);
        }
      } catch (error) {
        console.error(error);
        this.getUploadStatus();
        error.response
          ? this.$dialogs.alert(error.response.data)
          : this.$dialogs.alert(`Target SKUs update error.`);
      }
    },

    async getResult() {
      try {
        this.result = await expirationService.tryUpdateSKUResult(
          this.campaign.id
        );

        this.$modal.show(`try-update-sku-modal`);
      } catch (error) {
        console.error(error);
        error.response
          ? this.$dialogs.alert(error.response.data)
          : this.$dialogs.alert(`Target SKUs update error.`);
      }
    },

    async confirmUpdate() {
      try {
        await expirationService.tryConfirmUpdateSKU(this.campaign.id);
        await this.fetchSelectedSKUs();

        this.$notify("Confirm success");
        this.getUploadStatus();
        this.$emit("updated");
      } catch (error) {
        console.error(error);
        error.response
          ? this.$dialogs.alert(error.response.data)
          : this.$notify("Try upload error");
      }
    },

    async getSKUList() {
      this.skus = await expirationService.getSKUList();
    },

    async fetchSelectedSKUs() {
      this.skuSelected = await expirationService.getCampaignSKUs(
        this.campaign.id
      );
    },

    async getUploadStatus() {
      this.status = await expirationService.getUploadStatus(this.campaign.id);
    },

    startPullingStatus() {
      this.pullingId = setInterval(this.getUploadStatus, 5000);
    },

    stopPullingStatus() {
      clearInterval(this.pullingId);
      this.pullingId = null;
    },

    add(sku) {
      return {
        value: sku,
        text: sku,
      };
    },
  },

  beforeDestroy() {
    this.stopPullingStatus();
  },

  async mounted() {
    await this.fetchSelectedSKUs();
  },

  async created() {
    await this.getSKUList();
    await this.getUploadStatus();
  },
};
</script>

<style lang="scss" scoped>
.wrapper-upload-form {
  display: flex;
  margin: 2em auto;
  align-items: center;
  justify-content: space-between;

  .label-blue {
    color: #2b69bb;
    font-weight: 500;
  }
}
</style>
