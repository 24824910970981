<template>
  <div class="container">
    <div class="row">
      <table class="table table-borderless">
        <tr>
          <td class="w-200">
            <h4>Success message (max 60 char)</h4>
          </td>
          <td>
            <input
              type="text"
              maxlength="60"
              @change="update"
              :disabled="!canEdit"
              class="form-control"
              name="success_message"
              v-model="success_message"
            />
          </td>
        </tr>
        <tr>
          <td>
            <h4>Expiration message (max 60 char)</h4>
          </td>
          <td>
            <input
              type="text"
              maxlength="60"
              @change="update"
              :disabled="!canEdit"
              class="form-control"
              name="expiration_message"
              v-model="expiration_message"
            />
          </td>
        </tr>
        <tr>
          <td>
            <h4>Invalid message (max 60 char)</h4>
          </td>
          <td>
            <input
              type="text"
              maxlength="60"
              @change="update"
              :disabled="!canEdit"
              class="form-control"
              name="invalid_message"
              v-model="invalid_message"
            />
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import { expirationService } from "@/api/client";

export default {
  name: "Messages",

  props: ["campaign"],

  data() {
    return {
      success_message: null,
      expiration_message: null,
      invalid_message: null,
    };
  },

  computed: {
    canEdit() {
      return ["draft", "paused", "ongoing"].includes(this.campaign.status);
    },
  },

  mounted() {
    const { success_message, expiration_message, invalid_message } =
      this.campaign;

    this.success_message = success_message;
    this.expiration_message = expiration_message;
    this.invalid_message = invalid_message;
  },

  methods: {
    async update(event) {
      const { name } = event.currentTarget;

      const form = {};
      form[name] = this[name];

      try {
        await expirationService.updateCampaign(this.campaign.id, form);
        this.$notify(`Campaign updated.`);
        this.$emit("updated");
      } catch (error) {
        error.response
          ? this.$dialogs.alert(error.response.data)
          : this.$dialogs.alert(`Update Campaign error.`);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.row {
  margin-bottom: 1rem;
}

table tr td input[type="text"] {
  width: 60%;
}

.form-control-label {
  color: #37474f;
  font-weight: 500;
  font-size: 1.286rem;

  padding-left: 0px;
  margin-bottom: 0.5em;
}
</style>
