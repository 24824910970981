<template>
  <div class="px-15" v-if="campaign">
    <div class="btn-group">
      <button
        type="button"
        class="btn btn-primary btn-round"
        :class="{ 'dropdown-toggle': validStatus }"
        data-toggle="dropdown"
        aria-expanded="false"
        style="text-transform: uppercase"
      >
        {{ campaign.status }}
      </button>
      <div class="dropdown-menu" role="menu" v-if="validStatus">
        <a
          v-if="campaign.status == 'draft'"
          class="dropdown-item"
          href="javascript:void(0)"
          role="menuitem"
          @click="update('scheduled')"
        >
          <i class="icon md-long-arrow-up" aria-hidden="true"></i>Publish
        </a>
        <a
          v-if="campaign.status == 'scheduled'"
          class="dropdown-item"
          href="javascript:void(0)"
          role="menuitem"
          @click="update('draft')"
        >
          <i class="icon md-long-arrow-down" aria-hidden="true"></i>Unpublished
        </a>
        <a
          v-if="campaign.status == 'draft'"
          class="dropdown-item"
          href="javascript:void(0)"
          role="menuitem"
          @click="update('archived')"
        >
          <i class="icon md-archive" aria-hidden="true"></i>Archive
        </a>
        <a
          v-if="campaign.status == 'archived'"
          class="dropdown-item"
          href="javascript:void(0)"
          role="menuitem"
          @click="update('draft')"
        >
          <i class="icon mdi mdi-package-up" aria-hidden="true"></i>Unarchive
        </a>
        <a
          v-if="campaign.status == 'ongoing'"
          class="dropdown-item"
          href="javascript:void(0)"
          role="menuitem"
          @click="update('paused')"
        >
          <i class="icon mdi mdi-pause" aria-hidden="true"></i>Pause
        </a>
        <a
          v-if="campaign.status == 'paused'"
          class="dropdown-item"
          href="javascript:void(0)"
          role="menuitem"
          @click="update('ongoing')"
        >
          <i class="icon md-refresh" aria-hidden="true"></i>Restart
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { expirationService } from "@/api/client";
import { responseErrorMapping } from "../model/error";

export default {
  props: ["campaign"],
  computed: {
    validStatus() {
      return ["draft", "scheduled", "archived", "ongoing", "paused"].includes(
        this.campaign.status
      );
    },
    isPass() {
      return moment(new Date()) > moment(this.campaign.start_date);
    },
    isStartMoreThanEnd() {
      return moment(this.campaign.start_date) > moment(this.campaign.end_date);
    },
  },
  methods: {
    validate(status) {
      switch (status) {
        case "scheduled":
          // scheduled -> draft
          if (this.campaign.status == "draft") {
            // is start was pass
            if (this.isPass) {
              throw new Error(
                "Start date ใส่ค่าได้ตั้งแต่วันเวลาที่ในอนาคตเท่านั้น"
              );
            }

            // is start more than end
            if (this.isStartMoreThanEnd) {
              throw new Error(
                "กรุณาระบุวันเวลาเริ่มที่น้อยกว่าวันสิ้นสุด campaign"
              );
            }
          }

          break;
      }
    },

    async update(status) {
      try {
        this.validate(status);

        const { id } = this.campaign;

        const form = { status };

        await expirationService.updateCampaign(id, form);
        this.$emit("updated");
        this.$notify("Status updated");
      } catch (error) {
        error.response
          ? this.$dialogs.alert(responseErrorMapping(error.response.data))
          : this.$dialogs.alert(error.message);
      }
    },
  },
};
</script>
