var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.campaign
    ? _c("div", { staticClass: "px-15" }, [
        _c("div", { staticClass: "btn-group" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-primary btn-round",
              class: { "dropdown-toggle": _vm.validStatus },
              staticStyle: { "text-transform": "uppercase" },
              attrs: {
                type: "button",
                "data-toggle": "dropdown",
                "aria-expanded": "false",
              },
            },
            [_vm._v(" " + _vm._s(_vm.campaign.status) + " ")]
          ),
          _vm.validStatus
            ? _c(
                "div",
                { staticClass: "dropdown-menu", attrs: { role: "menu" } },
                [
                  _vm.campaign.status == "draft"
                    ? _c(
                        "a",
                        {
                          staticClass: "dropdown-item",
                          attrs: {
                            href: "javascript:void(0)",
                            role: "menuitem",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.update("scheduled")
                            },
                          },
                        },
                        [
                          _c("i", {
                            staticClass: "icon md-long-arrow-up",
                            attrs: { "aria-hidden": "true" },
                          }),
                          _vm._v("Publish "),
                        ]
                      )
                    : _vm._e(),
                  _vm.campaign.status == "scheduled"
                    ? _c(
                        "a",
                        {
                          staticClass: "dropdown-item",
                          attrs: {
                            href: "javascript:void(0)",
                            role: "menuitem",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.update("draft")
                            },
                          },
                        },
                        [
                          _c("i", {
                            staticClass: "icon md-long-arrow-down",
                            attrs: { "aria-hidden": "true" },
                          }),
                          _vm._v("Unpublished "),
                        ]
                      )
                    : _vm._e(),
                  _vm.campaign.status == "draft"
                    ? _c(
                        "a",
                        {
                          staticClass: "dropdown-item",
                          attrs: {
                            href: "javascript:void(0)",
                            role: "menuitem",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.update("archived")
                            },
                          },
                        },
                        [
                          _c("i", {
                            staticClass: "icon md-archive",
                            attrs: { "aria-hidden": "true" },
                          }),
                          _vm._v("Archive "),
                        ]
                      )
                    : _vm._e(),
                  _vm.campaign.status == "archived"
                    ? _c(
                        "a",
                        {
                          staticClass: "dropdown-item",
                          attrs: {
                            href: "javascript:void(0)",
                            role: "menuitem",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.update("draft")
                            },
                          },
                        },
                        [
                          _c("i", {
                            staticClass: "icon mdi mdi-package-up",
                            attrs: { "aria-hidden": "true" },
                          }),
                          _vm._v("Unarchive "),
                        ]
                      )
                    : _vm._e(),
                  _vm.campaign.status == "ongoing"
                    ? _c(
                        "a",
                        {
                          staticClass: "dropdown-item",
                          attrs: {
                            href: "javascript:void(0)",
                            role: "menuitem",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.update("paused")
                            },
                          },
                        },
                        [
                          _c("i", {
                            staticClass: "icon mdi mdi-pause",
                            attrs: { "aria-hidden": "true" },
                          }),
                          _vm._v("Pause "),
                        ]
                      )
                    : _vm._e(),
                  _vm.campaign.status == "paused"
                    ? _c(
                        "a",
                        {
                          staticClass: "dropdown-item",
                          attrs: {
                            href: "javascript:void(0)",
                            role: "menuitem",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.update("ongoing")
                            },
                          },
                        },
                        [
                          _c("i", {
                            staticClass: "icon md-refresh",
                            attrs: { "aria-hidden": "true" },
                          }),
                          _vm._v("Restart "),
                        ]
                      )
                    : _vm._e(),
                ]
              )
            : _vm._e(),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }